import React, { useState, useEffect } from "react";
import { Modal } from "./Modal";
import { eventEmitter } from "../../utils/helpers/eventEmitter";

export const TicketModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [onClose, setOnClose] = useState(() => () => {
    /* no-op */
  });

  useEffect(() => {
    const handleOpen = (data) => {
      setMessage(
        data.message
          ? data.message
          : "You Free ticket is on the way. Please wait until a host approves it. See you soon!"
      );
      setIsOpen(true);

      if (data.onClose) {
        setOnClose(() => data.onClose);
      }
    };

    eventEmitter.on("SHOW_TICKET_MODAL", handleOpen);

    return () => {
      eventEmitter.off("SHOW_TICKET_MODAL", handleOpen);
    };
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <Modal
      classNames={{
        root: "!z-[1010]",
        overlay: "backdrop-blur-sm h-screen w-full",
        modalContainer: "!fixed right-0 !bottom-0",
        modal:
          "!m-0 rounded-xl w-full sm:w-96 !mt-5 h-full max-h-[calc(100vh-20px)]",
        closeButton: "!top-[20px]",
      }}
      styles={{ zIndex: "1010" }}
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl font-bold mb-4">Event ticket</h2>
          <p className="text-gray-600 text-center mb-6">{message}</p>
          <div className="flex gap-4">
            <button
              onClick={handleClose}
              className="px-4 py-2 bg-spark-primary text-white rounded-md hover:bg-spark-primary-dark"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
