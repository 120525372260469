import { useEffect, useState } from "react";
import {
  Calendar01Icon,
  Globe02Icon,
  MapsIcon,
  CheckmarkSquare02Icon,
} from "hugeicons-react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Separator } from "../../components/ui/separator";
import { currencyFormat } from "../../utils/currency.util";
import { useLocation, Link, useParams } from "react-router-dom";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "../../utils/constans";
import { api } from "../../Services/api-service";
import { eventEmitter } from "../../utils/helpers/eventEmitter";

const TicketPage = () => {
  const location = useLocation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [event, setEvent] = useState(location.state?.event);
  const [tickets, setTickets] = useState([]);

  // Cargar el evento y tickets si no viene en location.state
  useEffect(() => {
    const fetchEventData = async () => {
      if (!event) {
        setIsLoading(true);
        try {
          let eventId = id;

          // Si no hay ID desde useParams, extraerlo de la URL
          if (!eventId) {
            const match = location.pathname.match(/\/events\/([^/]+)\/tickets/);
            eventId = match ? match[1] : null;
          }

          if (eventId) {
            const response = await api.get(`/events/details/${eventId}`);
            if (response.data) {
              setEvent(response.data);
              setTickets(response.data.tickets || []);
            }
          }
        } catch (error) {
          console.error("Error loading event:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        // Si el event ya existe en state, solo extraemos los tickets
        setTickets(event.tickets || []);
      }
    };

    fetchEventData();
  }, [event, id, location.pathname]);

  const handleBuyClick = async (e, ticket) => {
    e.preventDefault();
    console.log("ticket", ticket);
    try {
      const stripePromise = await loadStripe(STRIPE_PUBLIC_KEY);

      const response = await api.post(`/events/guests/add`, {
        eventId: event?.id,
        ticketId: ticket?.id,
      });

      if (response.data.status === "pending") {
        eventEmitter.emit("SHOW_TICKET_MODAL", {
          message: response.data.message,
          onClose: () => {
            window.location.href = `/events/${event.id}`;
          },
        });
        return;
      } else if (response.data.status === "approved") {
        window.location.href = `/events/${event.id}`;
        return;
      }

      if (!response?.data?.id) return;

      const session = await response.data?.id;
      stripePromise.redirectToCheckout({
        sessionId: session,
      });
    } catch (error) {
      console.error("Payment error:", error);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="text-center">
          <p className="text-gray-600 mb-4">Loading event information...</p>
        </div>
      </div>
    );
  }

  if (!event) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="text-center">
          <p className="text-red-600 mb-4">
            Error loading ticket: Event not found
          </p>
          <Link to="/my-tickets">
            <button className="bg-spark-primary text-white px-4 py-2 rounded-md">
              Back to My Tickets
            </button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-4 md:p-8">
      <div className="mx-auto max-w-6xl">
        <div className="mb-8 text-center">
          <h1 className="text-4xl font-bold">{event.title}</h1>
          <div className="mt-2 flex items-center justify-center gap-2 text-muted-foreground">
            {event.type === "online" ? (
              <>
                <Globe02Icon className="h-5 w-5" />

                <span>Online Event</span>
              </>
            ) : (
              <>
                <MapsIcon className="h-5 w-5" />

                <span>In-person Event</span>
              </>
            )}
          </div>
        </div>

        <div className="grid gap-6 md:grid-cols-2">
          {tickets.map((ticket, index) => (
            <Card key={index} className="relative overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-b from-spark-500/10 to-transparent pointer-events-none" />

              <CardHeader>
                <div className="flex items-start justify-between">
                  <div>
                    <CardTitle className="text-2xl font-bold">
                      {ticket.name}
                    </CardTitle>
                    <p className="mt-2 text-3xl font-bold text-spark-600">
                      {currencyFormat(ticket.currency, ticket.price)}
                    </p>
                  </div>
                </div>
              </CardHeader>

              <CardContent className="space-y-6">
                <div className="space-y-2">
                  <div className="flex items-start gap-2">
                    <Calendar01Icon className="h-5 w-5 text-spark-600 shrink-0" />
                    <div>
                      <p className="font-medium">
                        {moment(event.startAt).format(
                          "dddd, MMMM Do YYYY hh:mm A"
                        )}
                      </p>
                    </div>
                  </div>
                  {event.type !== "online" && (
                    <>
                      <div className="flex items-start gap-2">
                        <MapsIcon className="h-5 w-5 text-spark-600 shrink-0" />
                        <p>{event.address}</p>
                      </div>
                    </>
                  )}
                  {ticket.description != null && (
                    <p className="font-medium">
                      {ticket.description || "No description"}
                    </p>
                  )}
                </div>

                <Separator />

                <div>
                  <div className="mb-3 flex items-center gap-2">
                    <h3 className="font-semibold">What's included:</h3>
                  </div>
                  <ul className="space-y-2">
                    {ticket.included.map((item, i) => (
                      <li key={i} className="flex items-center gap-2">
                        <CheckmarkSquare02Icon className="h-5 w-5 text-spark-600" />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </CardContent>

              <CardFooter>
                <button
                  className="mt-4 bg-spark-primary text-white rounded-lg p-2 w-full hover:bg-spark-800 transition-colors"
                  onClick={(e) => {
                    console.log("ticket", ticket);
                    handleBuyClick(e, ticket);
                  }}
                >
                  Purchase For {currencyFormat(ticket.currency, ticket.price)}
                </button>
              </CardFooter>
            </Card>
          ))}
        </div>

        <div className="mt-8 text-center text-sm text-muted-foreground">
          <p>
            Hosted By:{" "}
            {event.hosts.map((host, i) => (
              <span key={host.id}>
                {host.name}
                {i > event.hosts.lenght && ", "}
              </span>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TicketPage;
