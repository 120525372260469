import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { api } from "../../Services/api-service";
import authSvc from "../../Services/auth-service";
import notificationSvc from "../../Services/notification";
import { useNavigate } from "react-router-dom";

const GoogleOAuth = ({ onLogin }) => {
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      const auth = await api.post("/auth/google", response);

      if (!auth.data?.email) return;

      notificationSvc.info(`Logged in as ${auth.data?.email}`);

      const { isLoggedIn, profileCreated } = await authSvc.login(
        null,
        null,
        auth
      );

      if (isLoggedIn) {
        onLogin();

        
        if (profileCreated) {
          const redirectUrl = localStorage.getItem("redirectAfterLogin");

          if (
            redirectUrl &&
            redirectUrl.length > 0 &&
            redirectUrl !== "/login"
          ) {
            navigate(redirectUrl);
          } else {
            navigate("/");
          }
          localStorage.removeItem("redirectAfterLogin");
        } else {
          navigate("/profile-setup");
        }
      }
    },
    onError: (error) => {
      notificationSvc.error(
        "Failed to login with google" + error.error_description
      );
    },
  });

  return (
    <button
      onClick={login}
      className="flex-1 h-[48px] w-[48px] flex items-center justify-center gap-2 rounded-lg border border-slate-300 bg-white p-2 text-sm font-medium text-black outline-none focus:ring-2 focus:ring-spark-500 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60"
    >
      <img
        src="https://www.svgrepo.com/show/475656/google-color.svg"
        alt="Google"
        className="h-[18px] w-[18px] "
      />
      Google
    </button>
  );
};

export default GoogleOAuth;
