import React, { useEffect, useState } from "react";
import Select from "react-select";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";
import { useNavigate } from "react-router-dom";
import authSvc from "../../Services/auth-service";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttributes } from "../../redux/attributeSlice";
import { Country, State, City } from "country-state-city";
import { Circles } from "react-loader-spinner";

const Formdiv = ({ step, handleSave, handleBack, isLastStep, setStep }) => {
  const dispatch = useDispatch();

  const { lookingFor, skill, interest, role } = useSelector(
    (state) => state.attributes || {}
  );

  const [selectedSkills, setSelectedSkills] = useState([]);

  const [selectedInterests, setSelectedInterests] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);

  const [preview, setPreview] = useState(null);

  const [loading, setLoading] = useState(false); // Add loading state

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [selectedState, setSelectedState] = useState(null);

  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    if (selectedFile) {
      setLoading(true); // Start loading when a file is selected
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      setPreview(null);
    }
  }, [selectedFile]);

  const handleImageLoad = () => {
    setLoading(false); // Stop loading when the image has loaded
  };

  const [formData, setFormData] = useState({
    personalDetails: {
      selectedPronoun: "",
      country: "",
      state: "",
      city: "",
      tagline: "",
    },
    profile: {
      role: "",
      lookingFor: "",
      businessStage: null,
      skills: [],
      interests: [],
    },
  });

  const navigate = useNavigate();

  const pronounOptions = [
    { value: "She/Her", label: "She/Her" },
    { value: "He/Him", label: "He/Him" },
    { value: "They/Them", label: "They/Them" },
  ];

  // En el useEffect que carga los datos al iniciar
  useEffect(() => {
    const savedFormData = localStorage.getItem("profileSetupData");
    const savedStep = localStorage.getItem("profileSetupStep");

    if (savedFormData) {
      const parsedData = JSON.parse(savedFormData);
      setFormData(parsedData);

      if (parsedData.profile.skills) {
        setSelectedSkills(parsedData.profile.skills);
      }
      if (parsedData.profile.interests) {
        setSelectedInterests(parsedData.profile.interests);
      }

      if (parsedData.personalDetails.selectedPronoun) {
        const selectedPronounOption = pronounOptions.find(
          (option) =>
            option.value === parsedData.personalDetails.selectedPronoun
        );
        if (selectedPronounOption) {
          // Actualizar visualmente el Select de pronombres
          document
            .querySelector('input[name="pronouns"]')
            ?.parentElement?.parentElement?.querySelector(
              ".css-1dimb5e-singleValue"
            )
            ?.setAttribute("data-value", selectedPronounOption.value);
        }
      }

      const country = parsedData.personalDetails.country;
      if (country) {
        // Buscar el objeto país
        const countryBase = Country.getAllCountries().find(
          (c) => c.name === country
        );
        if (countryBase) {
          // Formatear el objeto como lo espera react-select
          const countryObj = {
            value: countryBase.isoCode,
            label: countryBase.name,
            ...countryBase,
          };
          setSelectedCountry(countryObj);

          // Establecer el estado seleccionado
          const stateName = parsedData.personalDetails.state;
          if (stateName) {
            const stateBase = State.getStatesOfCountry(countryObj.isoCode).find(
              (s) => s.name === stateName
            );
            if (stateBase) {
              // Formatear el objeto estado
              const stateObj = {
                value: stateBase.isoCode,
                label: stateBase.name,
                ...stateBase,
              };
              setSelectedState(stateObj);

              // Establecer la ciudad seleccionada
              const cityName = parsedData.personalDetails.city;
              if (cityName) {
                const cityBase = City.getCitiesOfState(
                  countryObj.isoCode,
                  stateObj.isoCode
                ).find((c) => c.name === cityName);
                if (cityBase) {
                  // Formatear el objeto ciudad
                  const cityObj = {
                    value: cityBase.name,
                    label: cityBase.name,
                    ...cityBase,
                  };
                  setSelectedCity(cityObj);
                }
              }
            }
          }
        }
      }

      if (localStorage.getItem("profileImagePreview")) {
        const previewData = localStorage.getItem("profileImagePreview");
        setPreview(previewData);

        // Convertir el preview base64 a un File/Blob
        const fetchRes = fetch(previewData);
        fetchRes.then((res) => {
          res.blob().then((blob) => {
            // Crear un archivo desde el blob
            const file = new File([blob], "profile-picture.jpg", {
              type: "image/jpeg",
            });
            setSelectedFile(file);
          });
        });
      }
    }

    // Restaurar el paso guardado
    if (savedStep) {
      setStep(parseInt(savedStep, 10));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStep]);

  useEffect(() => {
    localStorage.setItem("profileSetupStep", step);
  }, [step]);

  useEffect(() => {
    localStorage.setItem("profileSetupData", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    if (preview) {
      localStorage.setItem("profileImagePreview", preview);
    }
  }, [preview]);

  const unlockProfile = async () => {
    try {
      const formDataToSend = new FormData();

      formDataToSend.append(
        "personalDetails[selectedPronoun]",
        formData.personalDetails.selectedPronoun
      );
      formDataToSend.append(
        "personalDetails[city]",
        formData.personalDetails.city
      );
      formDataToSend.append(
        "personalDetails[tagline]",
        formData.personalDetails.tagline
      );
      formDataToSend.append(
        "personalDetails[country]",
        formData.personalDetails.country
      );
      formDataToSend.append(
        "personalDetails[state]",
        formData.personalDetails.state
      );
      if (selectedFile) {
        formDataToSend.append("profileImage", selectedFile);
      } else if (preview && !selectedFile) {
        const response = await fetch(preview);
        const blob = await response.blob();
        formDataToSend.append("profileImage", blob, "profile-picture.jpg");
      }

      formDataToSend.append("profile[role]", formData.profile.role);
      formDataToSend.append("profile[lookingFor]", formData.profile.lookingFor);
      formDataToSend.append(
        "profile[businessStage]",
        formData.profile.businessStage
      );

      formData.profile.skills.forEach((skill) => {
        formDataToSend.append("profile[skills][]", skill);
      });

      formData.profile.interests.forEach((interest) => {
        formDataToSend.append("profile[interests][]", interest);
      });

      const response = await api.post(
        "users/profiles/create",
        formDataToSend,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response && response.status === 200) {
        notificationSvc.success("Profile Created Successfully");
        await authSvc.refresh();
        localStorage.setItem("profileCreated", true);
        localStorage.removeItem("profileSetupData");
        localStorage.removeItem("profileImagePreview");
        localStorage.removeItem("profileSetupStep");

        const redirectUrl = localStorage.getItem("redirectAfterLogin");

        if (redirectUrl && redirectUrl.length > 0 && redirectUrl !== "/login") {
          navigate(redirectUrl);
        } else {
          navigate("/");
        }
        localStorage.removeItem("redirectAfterLogin");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const validateStep = () => {
    switch (step) {
      case 2:
        if (
          !formData.personalDetails.selectedPronoun ||
          !formData.personalDetails.city ||
          !formData.personalDetails.tagline
        ) {
          notificationSvc.error(
            "Please complete all personal information fields."
          );
          return false;
        }
        break;
      case 3:
        if (!selectedFile && !preview) {
          notificationSvc.error("Please upload a profile picture.");
          return false;
        }
        break;
      case 4:
        if (!formData.profile.role) {
          notificationSvc.error("Please select your role.");
          return false;
        }
        break;
      case 5:
        if (!formData.profile.lookingFor) {
          notificationSvc.error("Please select what you are looking for.");
          return false;
        }
        break;
      case 6:
        if (selectedSkills.length === 0) {
          notificationSvc.error("Please select at least one skill.");
          return false;
        }
        break;
      case 7:
        if (selectedInterests.length === 0) {
          notificationSvc.error("Please select at least one interest.");
          return false;
        }
        break;
      default:
        break;
    }
    return true;
  };

  const handleContinue = () => {
    if (validateStep()) {
      if (isLastStep) {
        unlockProfile();
      } else {
        handleSave();
      }
    }
  };

  const toggleSkills = (option) => {
    let updatedSkills;
    if (selectedSkills.includes(option)) {
      updatedSkills = selectedSkills.filter((item) => item !== option);
    } else {
      if (selectedSkills.length < 2) {
        updatedSkills = [...selectedSkills, option];
      } else {
        notificationSvc.error("You can select maximum 2 skills.");
        return;
      }
    }
    setSelectedSkills(updatedSkills);
    setFormData((prevData) => ({
      ...prevData,
      profile: {
        ...prevData.profile,
        skills: updatedSkills,
      },
    }));
  };

  useEffect(() => {
    dispatch(fetchAttributes());
  }, [dispatch]);

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails,
        country: country?.name || "",
        state: "",
        city: "",
      },
    }));
    setSelectedState(null);
    setSelectedCity(null);
  };

  const handleStateChange = (state) => {
    setSelectedState(state);
    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails,
        state: state?.name || "",
        city: "",
      },
    }));
    setSelectedCity(null);
  };

  const handleCityChange = (city) => {
    setSelectedCity(city);
    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails,
        city: city?.name || "",
      },
    }));
  };

  const countryOptions = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
    ...country,
  }));

  const stateOptions =
    selectedCountry &&
    State.getStatesOfCountry(selectedCountry?.isoCode).map((state) => ({
      value: state.isoCode,
      label: state.name,
      ...state,
    }));

  const cityOptions =
    selectedState &&
    City.getCitiesOfState(selectedCountry?.isoCode, selectedState?.isoCode).map(
      (city) => ({
        value: city.name,
        label: city.name,
        ...city,
      })
    );

  const toggleInterest = (option) => {
    let updatedInterests;
    if (selectedInterests.includes(option)) {
      updatedInterests = selectedInterests.filter((item) => item !== option);
    } else {
      if (selectedInterests.length < 3) {
        updatedInterests = [...selectedInterests, option];
      } else {
        notificationSvc.error("You can select maximum 3 Interests.");
        return;
      }
    }
    setSelectedInterests(updatedInterests);
    setFormData((prevData) => ({
      ...prevData,
      profile: {
        ...prevData.profile,
        interests: updatedInterests,
      },
    }));
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      height: 50,
      minHeight: 50,
      borderRadius: "8px",
      border: "1px solid #ccc",
      boxShadow: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: 50,
      padding: "0 8px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: 50,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#888888",
    }),
  };

  return (
    <div className="h-full px-5 sm:px-10 lg:px-5">
      {/* Step 2: Personal Information */}
      {step === 2 && (
        <>
          <div className="text-center sm:text-left">
            <h1 className="!font-lexendBold text-2xl sm:text-3xl lg:text-4xl text-slate-900">
              Personal Information
            </h1>
            <p className="!font-lexendRegular text-lg sm:text-xl lg:text-2xl text-slate-900 mt-3">
              Tell us more about you
            </p>
          </div>
          <form className="mt-10 space-y-5">
            <div>
              <label className="!font-lexendRegular text-sm sm:text-lg text-slate-900">
                Select Pronouns
              </label>
              <Select
                className="mt-2"
                options={pronounOptions}
                styles={customSelectStyles}
                name="pronouns"
                value={
                  pronounOptions.find(
                    (option) =>
                      option.value === formData.personalDetails.selectedPronoun
                  ) || null
                }
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    personalDetails: {
                      ...formData.personalDetails,
                      selectedPronoun: e.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label className="!font-lexendRegular text-sm sm:text-lg text-slate-900">
                Country
              </label>
              <Select
                className="mt-2"
                options={countryOptions}
                name="country"
                value={selectedCountry}
                onChange={handleCountryChange}
                styles={customSelectStyles}
              />
            </div>
            <div>
              <label className="!font-lexendRegular text-sm sm:text-lg text-slate-900">
                State
              </label>
              <Select
                className="mt-2"
                options={stateOptions}
                value={selectedState}
                onChange={handleStateChange}
                isDisabled={!selectedCountry}
                styles={customSelectStyles}
              />
            </div>
            <div>
              <label className="!font-lexendRegular text-sm sm:text-lg text-slate-900">
                City
              </label>
              <Select
                className="mt-2"
                options={cityOptions}
                value={selectedCity}
                onChange={handleCityChange}
                isDisabled={!selectedState}
                styles={customSelectStyles}
              />
            </div>
            <div>
              <label className="!font-lexendRegular text-sm sm:text-lg text-slate-900">
                Tagline that describes you best?
              </label>
              <textarea
                className="w-full mt-2 p-3 rounded-lg border border-slate-300 focus:border-spark-700 !font-lexendRegular"
                rows={3}
                placeholder="Write a tagline..."
                value={formData.personalDetails.tagline}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    personalDetails: {
                      ...formData.personalDetails,
                      tagline: e.target.value,
                    },
                  })
                }
              />
            </div>
          </form>
        </>
      )}

      {/* Step 3: Profile Picture */}
      {step === 3 && (
        <>
          <div className="">
            <h1 className="!font-lexendBold text-slate-900 text-4xl mb-4">
              Profile Picture
            </h1>
            <p className="!font-lexendRegular text-lg text-slate-900 mb-5">
              Upload your picture
            </p>
          </div>
          <form className="">
            <div className="relative w-full custom-border h-96 border-dashed rounded-lg bg-white">
              {preview && (
                <img
                  src={preview}
                  alt=""
                  className="w-full h-full rounded-lg object-cover"
                  onLoad={handleImageLoad}
                />
              )}
              {loading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Circles
                    height="40"
                    width="40"
                    color="#b30af2"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              )}
            </div>
            <div className="flex items-center mt-3 justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 hover:bg-gray-100"
              >
                <div className="flex flex-col items-center justify-center py-6 px-3">
                  <svg
                    width="55"
                    height="54"
                    viewBox="0 0 55 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M15.125 40.5V40.2975C15.125 38.3512 15.125 36.5648 15.3207 35.1135C15.5345 33.5138 16.0408 31.8218 17.4313 30.4335C18.8217 29.0408 20.5137 28.5345 22.1112 28.3185C23.5647 28.125 25.3512 28.125 27.2998 28.125H27.7002C29.6488 28.125 31.4352 28.125 32.8865 28.3207C34.4862 28.5345 36.1783 29.0407 37.5665 30.4312C38.9593 31.8217 39.4655 33.5137 39.6815 35.1112C39.8727 36.5445 39.875 38.2972 39.875 40.212C45.6642 38.9745 50 33.885 50 27.792C50 22.2345 46.3843 17.505 41.3488 15.7837C40.6333 9.4365 35.1838 4.5 28.571 4.5C21.47 4.5 15.7145 10.188 15.7145 17.2057C15.7145 18.7582 15.9958 20.2433 16.511 21.6203C15.8956 21.5013 15.2703 21.441 14.6435 21.4403C9.31775 21.4425 5 25.7085 5 30.9713C5 36.234 9.31775 40.5 14.6435 40.5H15.125Z"
                      fill="#B30AF2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M27.5 31.5C23.2565 31.5 21.137 31.5 19.8185 32.8185C18.5 34.137 18.5 36.2565 18.5 40.5C18.5 44.7435 18.5 46.863 19.8185 48.1815C21.137 49.5 23.2565 49.5 27.5 49.5C31.7435 49.5 33.863 49.5 35.1815 48.1815C36.5 46.863 36.5 44.7435 36.5 40.5C36.5 36.2565 36.5 34.137 35.1815 32.8185C33.863 31.5 31.7435 31.5 27.5 31.5ZM31.5613 38.439L28.5597 35.4398C28.2784 35.1592 27.8973 35.0016 27.5 35.0016C27.1027 35.0016 26.7216 35.1592 26.4403 35.4398L23.4387 38.439C23.2938 38.577 23.1779 38.7427 23.098 38.9262C23.018 39.1097 22.9756 39.3073 22.9731 39.5075C22.9707 39.7076 23.0083 39.9062 23.0838 40.0916C23.1592 40.277 23.271 40.4454 23.4126 40.5869C23.5541 40.7285 23.7225 40.8403 23.9079 40.9157C24.0933 40.9912 24.2919 41.0288 24.492 41.0264C24.6922 41.0239 24.8898 40.9815 25.0733 40.9015C25.2568 40.8216 25.4225 40.7057 25.5605 40.5608L25.9993 40.122V44.5005C25.9993 44.8985 26.1574 45.2802 26.4388 45.5617C26.7203 45.8431 27.102 46.0012 27.5 46.0012C27.898 46.0012 28.2797 45.8431 28.5612 45.5617C28.8426 45.2802 29.0007 44.8985 29.0007 44.5005V40.122L29.4395 40.5608C29.7231 40.8309 30.1011 40.9794 30.4928 40.9746C30.8845 40.9699 31.2587 40.8122 31.5357 40.5352C31.8127 40.2582 31.9704 39.884 31.9751 39.4923C31.9799 39.1006 31.8314 38.7226 31.5613 38.439Z"
                      fill="#B30AF2"
                    />
                  </svg>

                  <p className="mb-2 text-slate-900">
                    <span className="!font-lexendRegular text-lg text-spark-700">
                      Click to upload file
                    </span>
                    &nbsp; or drag and drop
                  </p>
                  <p className="!font-lexendRegular text-md text-slate-900">
                    File format: .PNG, .JPG, .GIF up to 4MB
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                  }}
                  accept="image/*"
                />
              </label>
            </div>
          </form>
        </>
      )}

      {/* Step 4 to Step 7 (No Changes in Layout) */}
      {step === 4 && (
        <>
          <div className="text-center sm:text-left">
            <h1 className="!font-lexendBold text-2xl sm:text-3xl lg:text-4xl text-slate-900">
              Your Role
            </h1>
            <p className="!font-lexendRegular text-lg sm:text-xl text-slate-900 mt-3">
              What do you bring? (Choose 1)
            </p>
          </div>
          {role?.map((roleItem) => (
            <div
              key={roleItem}
              className={`border-2 ${
                formData.profile.role === roleItem
                  ? "border-spark-700"
                  : "border-slate-200"
              } mt-4 p-1 rounded-full`}
            >
              <button
                className={`w-full py-5 rounded-full text-slate-900 !font-lexendMedium text-3xl ${
                  formData.profile.role === roleItem
                    ? "!bg-spark-700 text-white"
                    : "bg-slate-200"
                }`}
                onClick={() => {
                  setFormData((prevData) => ({
                    ...prevData,
                    profile: {
                      ...prevData.profile,
                      role: roleItem,
                    },
                  }));
                }}
              >
                {roleItem}
              </button>
            </div>
          ))}
        </>
      )}

      {step === 5 && (
        <>
          <div className="text-center sm:text-left">
            <h1 className="!font-lexendBold text-2xl sm:text-3xl lg:text-4xl text-slate-900">
              Looking For
            </h1>
            <p className="!font-lexendRegular text-lg sm:text-xl text-slate-900 mt-3">
              What do you need? (Choose 1)
            </p>
          </div>
          {lookingFor.map((item) => (
            <div
              key={item}
              className={`border-2 ${
                formData.profile.lookingFor === item
                  ? "border-spark-700"
                  : "border-slate-200"
              } mt-4 p-1 rounded-full`}
            >
              <button
                className={`w-full py-5 rounded-full text-slate-900 !font-lexendMedium text-3xl ${
                  formData.profile.lookingFor === item
                    ? "!bg-spark-700 text-white"
                    : "bg-slate-200"
                }`}
                onClick={() => {
                  setFormData((prevData) => ({
                    ...prevData,
                    profile: {
                      ...prevData.profile,
                      lookingFor: item,
                    },
                  }));
                }}
              >
                {item}
              </button>
            </div>
          ))}
        </>
      )}

      {step === 6 && (
        <>
          <div className="text-center sm:text-left">
            <h1 className="!font-lexendBold text-2xl sm:text-3xl lg:text-4xl text-slate-900">
              Your Skills
            </h1>
            <p className="!font-lexendRegular text-lg sm:text-xl text-slate-900 mt-3">
              What are you best at? (Choose up to 2)
            </p>
          </div>
          {skill.map((item) => (
            <div
              key={item}
              className={`border-2 ${
                selectedSkills.includes(item)
                  ? "border-spark-700"
                  : "border-slate-200"
              } mt-4 p-1 rounded-full`}
            >
              <button
                className={`w-full py-5 rounded-full text-slate-900 !font-lexendMedium text-3xl ${
                  selectedSkills.includes(item)
                    ? "!bg-spark-700 text-white"
                    : "bg-slate-200"
                }`}
                onClick={() => toggleSkills(item)}
              >
                {item}
              </button>
            </div>
          ))}
        </>
      )}

      {step === 7 && (
        <>
          <div className="text-center sm:text-left">
            <h1 className="!font-lexendBold text-2xl sm:text-3xl lg:text-4xl text-slate-900">
              Your Interests
            </h1>
            <p className="!font-lexendRegular text-lg sm:text-xl text-slate-900 mt-3">
              Which industries are you interested in? (Choose up to 3)
            </p>
          </div>
          {interest.map((item) => (
            <div
              key={item}
              className={`border-2 ${
                selectedInterests.includes(item)
                  ? "border-spark-700"
                  : "border-slate-200"
              } mt-4 p-1 rounded-full`}
            >
              <button
                className={`w-full py-5 rounded-full text-slate-900 !font-lexendMedium text-3xl ${
                  selectedInterests.includes(item)
                    ? "!bg-spark-700 text-white"
                    : "bg-slate-200"
                }`}
                onClick={() => toggleInterest(item)}
              >
                {item}
              </button>
            </div>
          ))}
        </>
      )}

      {/* Navigation Buttons */}
      <div className="flex flex-col sm:flex-row justify-between mt-10 gap-5">
        <button
          className="flex items-center justify-center px-6 py-3 border rounded-lg border-slate-700 text-slate-700"
          onClick={handleBack}
        >
          Back
        </button>
        <button
          className="flex items-center justify-center px-6 py-3 bg-spark-700 text-white rounded-lg"
          onClick={handleContinue}
        >
          {isLastStep ? "Submit" : "Continue"}
        </button>
      </div>
    </div>
  );
};

export default Formdiv;
