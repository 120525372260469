import { useEffect } from "react";

const GoogleAds = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (error) {
        console.error("Error pushing adsbygoogle:", error);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
      <ins
        className="adsbygoogle"
        style={{ display: "inline-block", width: "728px", height: "90px" }}
        data-ad-client="ca-pub-1260800738380666"
        data-ad-slot="2661506754"
      ></ins>
  );
};

export default GoogleAds;
