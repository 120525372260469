import React, { useCallback, useEffect, useState, useRef } from "react";
import { ZendeskProvider } from "react-use-zendesk";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Dashboard from "./pages/Dasboard/Dashboard";
import UnlockPotential from "./pages/UnlockYourPotential/profileSetup.jsx";
import spinnerSvc from "./Services/spinner";
import LoaderSpinner from "./components/Loader";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signup from "./pages/Signup/Signup";
import CheckoutSuccess from "./pages/Checkout/success-checkout";
import ChechoutFailed from "./pages/Checkout/failed-checkout";
import Conflict from "./components/CheckoutStatus/Conflict";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy.jsx";
import TermsConditions from "./pages/TermsConditions/TermsConditions.jsx";
import Chat from "./pages/Chat/Chat.jsx";
import Navbar from "./components/Navigation/navbar.js";
import "react-responsive-modal/styles.css";
import { api } from "./Services/api-service.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivePlan } from "./redux/activePlanSlice.js";
import ForgotPassword from "./pages/ForgotPassword/FrogotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Update from "./pages/Profile/Update.jsx";
import "preline/preline";
import Email from "./pages/Verification/Email.jsx";
import LinkedInRedirect from "./pages/LinkedInRedirect";
import ProfileComp from "./pages/Profile/ProfileComp.jsx";
import Events from "./pages/Events/Index";
import TicketPreviewPage from "./pages/Events/TicketPreviewPage";
import Subscriptions from "./pages/Subscriptions/Subscriptions";
import EventMatches from "./components/Events/EventMatches.jsx";
import { PremiumModal } from "./components/Modals/PremiumModel";
import { TicketModal } from "./components/Modals/TicketModal.jsx";

import TicketPage from "./pages/Events/TicketPage.jsx";
import GoogleAds from "./components/ads/GoogleAds";

const ProtectedRoute = ({ isLoggedIn, children }) => {
  const location = useLocation();

  if (isLoggedIn) {
    return children;
  } else {
    localStorage.setItem("redirectAfterLogin", location.pathname);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const publicRoutes = [
    "/login",
    "/about",
    "/0to1",
    "/northstar",
    "/register",
    "/privacy-policy",
    "/terms-and-conditions",
    "/forgot-password",
    "/reset-password",
  ];

  const verifyProfile = useCallback(async () => {
    const profileCreated = localStorage.getItem("profileCreated");
    if (profileCreated !== "true") {
      navigate("/profile-setup");
    }
  }, [navigate]);

  const getPlans = async () => {
    const response = await api.get("plans");
    if (response && response.status === 200) {
      setPlans(response.data);
    }
  };

  const dispatch = useDispatch();

  const activePlan = useSelector((state) => state.activePlan.activeplan);

  useEffect(() => {
    if (isLoggedIn && !activePlan) {
      dispatch(fetchActivePlan());
    }
  }, [dispatch, activePlan, isLoggedIn]);

  const getActivePlan = () => {
    if (activePlan === null) {
      return { planName: "Free", planId: null, price: 0 };
    } else {
      const activatedPlan = plans.find((plan) => {
        return plan.planId === activePlan.planId;
      });
      return activatedPlan;
    }
  };

  const validateLoggedInStatus = useCallback(async () => {
    const token = localStorage.getItem("idToken");
    setIsLoggedIn(!!token);
    setIsAuthChecked(true);
  }, []);

  useEffect(() => {
    validateLoggedInStatus();
  }, [validateLoggedInStatus]);

  useEffect(() => {
    if (isLoggedIn) {
      getPlans();
      verifyProfile();
    }
  }, [isLoggedIn, verifyProfile]);

  useEffect(() => {
    const subscription = spinnerSvc.requestInProgress.subscribe((isLoading) =>
      setIsLoading(isLoading)
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  const adRef = useRef(null);
  const [adHeight, setAdHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (adRef.current) {
        setAdHeight(adRef.current.offsetHeight);
      }
    };

    // Llamamos inicialmente para tomar el tamaño
    updateHeight();

    // Usar ResizeObserver para detectar cambios en el tamaño del contenedor
    let resizeObserver;
    if ("ResizeObserver" in window) {
      resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === adRef.current) {
            setAdHeight(entry.contentRect.height);
          }
        }
      });
      if (adRef.current) {
        resizeObserver.observe(adRef.current);
      }
    }

    // En fallback puedes usar MutationObserver si ResizeObserver no está disponible
    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      if (adRef.current) {
        setAdHeight(adRef.current.offsetHeight);
      }
    };

    // Ejecutar el updateHeight de forma periódica
    const intervalId = setInterval(updateHeight, 100);

    // Detener el polling después de 5 segundos
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 5000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []);

  if (!isAuthChecked) {
    return <LoaderSpinner />;
  }

  return (
    <ZendeskProvider apiKey="f1f44f6e-b67d-4288-8078-aea6959daa84">
      <>
        <PremiumModal />
        <TicketModal />
        <div className="App flex">
          <ToastContainer
            hideProgressBar={true}
            autoClose={5000}
            transition={Bounce}
          />

          {isLoading && <LoaderSpinner />}

          {!publicRoutes.includes(location.pathname) &&
            isLoggedIn &&
            !location.pathname.includes("profile-setup") && (
              <Navbar
                firstName={user?.firstName}
                lastName={user?.lastName}
                displaypicture={user?.profilePicture?.publicUrl}
                activePlan={getActivePlan()?.price ?? 0}
              />
            )}

          <div
            className={`bg-spark-bg-primary w-full ${
              isLoggedIn && !location.pathname.includes("profile-setup")
                ? "mt-20"
                : ""
            }`}
          >
            {isLoggedIn &&
              !location.pathname.includes("profile-setup") &&
              getActivePlan()?.price === 0 && (
                <div ref={adRef} className="fixed min-w-full z-50">
                  <GoogleAds />
                </div>
              )}

            <div
              style={{
                marginTop: adHeight,
              }}
            >
              <Routes>
                {/* Public Routes */}
                <Route
                  path="/login"
                  element={
                    <Signup
                      isLoggedIn={isLoggedIn}
                      tab={"login"}
                      onLogin={() => setIsLoggedIn(true)}
                    />
                  }
                />

                <Route
                  path="/register"
                  element={
                    <Signup
                      isLoggedIn={isLoggedIn}
                      tab={"register"}
                      onLogin={() => setIsLoggedIn(true)}
                    />
                  }
                />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                <Route
                  path="/terms-and-conditions"
                  element={<TermsConditions />}
                />

                <Route path="/verify-email" element={<Email />} />

                <Route
                  path="/forgot-password"
                  element={<ForgotPassword isLoggedIn={isLoggedIn} />}
                />

                <Route
                  path="/reset-password"
                  element={<ResetPassword isLoggedIn={isLoggedIn} />}
                />

                <Route
                  path="/ticket/:guestId"
                  element={<TicketPreviewPage />}
                />

                {/* Protected Routes */}
                <Route
                  path="/"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <Dashboard user={user} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/profile-setup"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <UnlockPotential />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <ProfileComp
                        getActivePlan={getActivePlan}
                        plans={plans}
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/chat"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <Chat />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/checkout-success"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <CheckoutSuccess />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/checkout-failure"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <ChechoutFailed />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/checkout-conflict"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <Conflict />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/user-profile/update"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <Update />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/events"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <Events
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        user={user}
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/events/:id"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <Events
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        user={user}
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/events/:id/tickets"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <TicketPage
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        user={user}
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/events/:id/matches"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <EventMatches user={user} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/subscriptions"
                  element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                      <Subscriptions
                        getActivePlan={getActivePlan}
                        plans={plans}
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="*"
                  element={<Navigate to={isLoggedIn ? "/" : "/login"} />}
                />

                <Route path="/linkedin" element={<LinkedInRedirect />} />
              </Routes>
            </div>
          </div>
        </div>
      </>
    </ZendeskProvider>
  );
}

export default App;
