import GoogleOAuth from "../../components/OAuth/GoogleOAuth";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "../../utils/constans";
import LinkedinOAuth from "../../components/OAuth/LinkedinOAuth";

const OAuth = ({ onLogin }) => {
    return (
      <>
        <div className="mt-5 mb-5 flex items-center justify-center space-x-4">
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <GoogleOAuth onLogin={onLogin} />
          </GoogleOAuthProvider>
          <LinkedinOAuth onLogin={onLogin} />
        </div>
        <div className="flex items-center my-4 w-full">
          <div className="flex-1 h-px bg-gray-500"></div>
          <span className="px-4 text-gray-500 text-sm font-medium">OR</span>
          <div className="flex-1 h-px bg-gray-500"></div>
        </div>
      </>
    );
};

export default OAuth;