import React, { useEffect, useState } from "react";
import EventView from "../../components/Events/EventView";
import GroupedEvent from "../../components/Events/GroupedEvent";
import Pagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents, fetchMyEvents } from "../../redux/eventSlice";

import notificationSvc from "../../Services/notification";
import { useParams } from "react-router-dom";
import { api } from "../../Services/api-service";
import NoEventsMessage from "../../components/Events/NoEventMessage";
import { useNavigate } from "react-router-dom";

const Index = ({ setIsLoading, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const eventsData = useSelector((state) => state.events);

  const [events, setEvents] = useState({});
  const [page, setPage] = useState(1);
  const [perPage] = useState(2);
  const [activeEvent, setActiveEvent] = useState(null);

  const _tabs = [
    {
      title: "Upcoming Events",
      hashtag: "upcoming",
      isActive: true,
      selector: "events",
      type: "upcomingEvents",
    },
    {
      title: "Past Events",
      hashtag: "past",
      isActive: false,
      selector: "events",
      type: "pastEvents",
    },
    {
      title: "My Events",
      hashtag: "mine",
      isActive: false,
      selector: "myEvents",
      type: "upcomingEvents",
    },
  ];

  const [tabs, setTabs] = useState(_tabs);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchEvents());
    dispatch(fetchMyEvents(user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setIsLoading]);

  useEffect(() => {
    if (eventsData?.events?.upcomingEvents?.length) {
      setEvents(groupEventsByDate(eventsData?.events?.upcomingEvents));
      setIsLoading(false);
    }
  }, [eventsData?.events, setIsLoading]);

  const groupEventsByDate = (events) => {
    const groupedEvents = {};
    events.forEach((entry) => {
      const startTime = new Date(entry.startAt);
      const dateKey = startTime.toDateString();
      if (!groupedEvents[dateKey]) {
        groupedEvents[dateKey] = [];
      }
      groupedEvents[dateKey].push(entry);
    });
    return groupedEvents;
  };

  const handleManageClick = (eventData) => {
    if (!eventData.tickets || eventData.tickets.length <= 0)
      notificationSvc.info("Event does not have any tickets");
    else {
      navigate(`/events/${eventData.id}/tickets`, {
        state: { event: eventData },
      });
    }
  };

  const handleViewTicketClick = (eventData) => {
    if (eventData.userGuestId == null)
      notificationSvc.info("You are not a guest of this event");
    else {
      window.location.href = `/ticket/${eventData.userGuestId}`;
    }
  };

  const handleConnectClick = async (eventData) => {
    console.log(eventData);
    const response = await api.get(`/events/online/sso/${eventData.id}`);
    if (response && response.status === 200) {
      window.location.href = response.data.url;
    }
  };

  const getCurrentListing = (page, perPage) => {
    return Object.keys(events ?? {}).slice(
      (page - 1) * perPage,
      page * perPage
    );
  };

  const handleEventView = (date, i) => {
    window.scrollTo({
      top: 0,
    });
    setActiveEvent({ date, activeIndex: i });
  };

  const handleTabChange = (tabIndex) => {
    setTabs((prev) =>
      prev.map((t, i) =>
        i === tabIndex ? { ...t, isActive: true } : { ...t, isActive: false }
      )
    );

    const activeTab = _tabs[tabIndex];

    // const eventsToGroup = eventsData[activeTab.selector][activeTab.type] ?? [];

    let eventsToGroup = [];

    if (activeTab.hashtag === "mine") {
      eventsToGroup = [
        ...(eventsData.myEvents?.upcomingEvents || []),
        ...(eventsData.myEvents?.pastEvents || []),
      ];
    } else {
      eventsToGroup = eventsData[activeTab.selector][activeTab.type] ?? [];
    }

    setEvents(groupEventsByDate(eventsToGroup));
    setPage(1);
    setActiveEvent(null);
  };

  const getEventDetails = async () => {
    const response = await api.get(`/events/details/${id}`);
    if (response && response.status === 200) {
      setActiveEvent({ event: response.data });
    }
  };

  useEffect(() => {
    if (id) {
      getEventDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const viewedEvent = activeEvent?.event
    ? activeEvent.event
    : activeEvent?.date && typeof activeEvent.activeIndex === "number"
    ? events[activeEvent.date]?.[activeEvent.activeIndex]
    : null;

  return (
    <div className="sm:px-10 px-4">
      <ul className="mt-10 flex flex-wrap text-sm font-medium text-center border-b border-gray-200">
        {tabs.map((tab, i) => (
          <li key={i} className="me-2" onClick={() => handleTabChange(i)}>
            <a
              href={`#${tab.hashtag}`}
              aria-current="page"
              className={`inline-block p-4 px-6 rounded-t-lg ${
                tab.isActive
                  ? "text-white bg-spark-primary"
                  : "hover:bg-spark-bg-primary-light"
              }`}
            >
              {tab.title}
            </a>
          </li>
        ))}
      </ul>

      <div className="pt-4 flex justify-between space-y-2 sm:space-y-0 sm:flex-row flex-col">
        <div className="flex-1">
          <p className="text-3xl font-bold">
            {tabs.find((t) => t.isActive)?.title ?? "Upcoming Events"}
          </p>
        </div>
      </div>

      <div className="bg-white flex w-full 2xl:flex-row flex-col-reverse rounded-2xl mt-4 p-3">
        {Object.keys(events ?? {}).length <= 0 && <NoEventsMessage />}
        <div className="2xl:w-[55%] flex-1 w-full p-4">
          {getCurrentListing(page, perPage)?.map((date, i) => {
            return (
              <GroupedEvent
                key={i}
                handleEventView={handleEventView}
                activeEvent={activeEvent}
                date={date}
                events={events[date]}
                onManageClick={handleManageClick}
                onTicketClick={handleViewTicketClick}
                onConnectClick={handleConnectClick}
              />
            );
          })}
          {Object.keys(events ?? {}).length !== 0 && (
            <Pagination
              perPage={perPage}
              total={Object.keys(events ?? {}).length}
              activePage={page}
              onPageChange={(page) => {
                setPage(page);
              }}
            />
          )}
        </div>

        {viewedEvent && (
          <div className="2xl:w-[45%] w-full 2xl:sticky fixed top-0 bottom-0 right-0 2xl:top-20 h-screen 2-xl:h-auto 2xl:z-0 z-[1004] overflow-y-auto">
            <EventView
              closeEventView={() => setActiveEvent(null)}
              containerClass="2xl:border-s 2xl:mb-0 mb-2"
              event={viewedEvent}
              onManageClick={handleManageClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
