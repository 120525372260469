import React, { useState } from "react";
import Formdiv from "../../components/UnlockYourPotential/Formdiv";
import CheckList from "../../components/UnlockYourPotential/CheckList";
import Logo from "../../assets/images/spark-form-logo.png";
import { isMobile, isTablet } from "react-device-detect";

const UnlockPotential = () => {
  const [step, setStep] = useState(2);
  
  const handleSave = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 2) {
      setStep(step - 1);
    }
  };

  const lastStep = 7;

  const isMobileOrTablet = isMobile || isTablet;

  return (
    <div
      className={`min-h-screen flex items-center justify-center p-6 md:p-12 bg-slate-200`}
    >
      <div
        className={`flex ${
          isMobileOrTablet ? "flex-col" : "lg:flex-row"
        } shadow-custom bg-white/40 rounded-3xl w-full lg:h-full p-4 md:p-2`}
      >
        <section
          className={`w-full ${
            isMobileOrTablet ? "mb-6" : "lg:w-1/2 lg:mb-0"
          } bg-slate-900 rounded-3xl p-6 md:p-12`}
        >
          <img src={Logo} alt="Spark Logo" className="mb-6 w-24" />

          <CheckList handleSave={handleSave} step={step} />
        </section>

        <section
          className={`w-full sm:w-full md:w-full xl:w-1/2 p-4 md:p-10 ${
            isMobileOrTablet ? "" : "lg:pl-8"
          }`}
        >
          <Formdiv
            handleSave={handleSave}
            handleBack={handleBack}
            setStep={setStep}
            step={step}
            isLastStep={step === lastStep}
          />
        </section>
      </div>
    </div>
  );
};

export default UnlockPotential;
